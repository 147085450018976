import { SearchApiResponseInterface } from "../sub-interfaces/search-types";

export const SeacrhApiResponse: SearchApiResponseInterface = {
	boosted: [],
	boosted_distance: [],
	non_boosted: [],
	non_boosted_distance: [],
	cache_hit: false,
	filter_hash: "",
	search_log_id: "",
	server_execution_time: 0,
	search_duration: 0,
	no_of_boosted_results: 0,
	no_of_non_boosted_results: 0,
	total_no_of_results: 0,
	boosted_from_other_locations: [],
	boosted_from_other_locations_distance: [],
	no_of_boosted_results_from_other_locations: 0
};
