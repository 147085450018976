/*

? First, let's import the interface that will describe the shape of our user object.

*/

import StateInterface from "../state-interface";

/*

& Now let's describe an initial state for the user object. This is the state that will be used when the app first fires up. Think of it like a placeholder.

*/

const commonDate = "Fri Nov 18 2022 20:28:59 GMT+0530 (India Standard Time)";

const user_data: StateInterface["user"] = {
	_id: "",
	slug: "",
	first_name: "",
	last_name: "",
	date_of_birth: commonDate,
	gender: "",
	phone: "",
	country_code: "",
	alternate_phones: [],
	recovery_email: {
		email: "",
		is_verified: false,
	},
	profile_context: {
		is_business_profile: false,
		business_profile_id: "",
		business_profile_image: {
			file_id: "",
			mime_type: "",
			exists: false,
		},
		page_type: "",
		page_slug: "",
		page_title: "",
		remember_choice: false,
		subscription_information: {
			subscription_id: "",
			plan_slug: "",
			end_date: new Date("2100-01-01"),
			active: false,
		},
		kyc_info: {
			kyc_status: "",
		},
	},
	is_profile_completed: false,
	precise_location_enabled: false,
	kyc_info: {
		kyc_status: "",
		kyc_comment: "",
		kyc_profile_type: "",
		kyc_type: "",
		assigned_to: [],
		pan: {
			pan_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		selected_document: "",
		aadhaar: {
			aadhaar_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		voter_id: {
			voter_id_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		driving_license: {
			driving_license_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		passport: {
			passport_number: "",
			front_image_file_id: "",
			back_image_file_id: "",
		},
		tin_certificate: {
			tin_number: "",
			front_image_file_id: "",
		},
		gst_certificate: {
			gst_number: "",
			front_image_file_id: "",
		},
		license_from_legal_authority: {
			license_number: "",
			front_image_file_id: "",
		},
		memorandum_of_agreement: {
			memorandum_of_agreement_number: "",
			front_image_file_id: "",
		},
		rera_certificate: {
			rera_number: "",
			front_image_file_id: "",
		},
		partnership_deed: {
			partnership_deed_number: "",
			front_image_file_id: "",
		},
		partnership_registration_certificate: {
			partnership_reg_certificate_number: "",
			front_image_file_id: "",
		},
		article_of_association: {
			article_of_association_number: "",
			front_image_file_id: "",
		},
		certificate_of_incorporation: {
			certificate_of_incorporation_number: "",
			front_image_file_id: "",
		},
		llp_agreement: {
			llp_agreement_number: "",
			front_image_file_id: "",
		},
		internal_use_documents: [],
	},
	profile_picture: {
		file_id: "",
		original_file_server_path: "",
		exists: false,
	},
	cover_image: {
		file_id: "",
		original_file_server_path: "",
		exists: false,
	},
	beegru_points: 0,
	verified: false,
	address: {
		line1: "",
		line2: "",
		city: "",
		state: "",
		country: "",
		pincode: "",
		landmark: "",
	},
	access_level: "user",
	allocated_storage: 107374182400,
	first_timer: true,
	onboarding_complete: false,
	active: true,
	banned: {
		status: false,
		reason_for_ban: "",
		banned_by_user_id: "",
		banned_at: commonDate,
	},
	flags: [],
	employer_business_profile_id: "",
	user_privacy: {
		send_promotional_emails: true,
		auto_lead_forwarding: false,
		lead_forwarding_properties: [],
		receive_post_notifications: true,
		is_posts_default_public: true,
		is_files_default_public: true,
		is_notifications_default_allowed: true,
		is_profile_default_public: true,
	},
	pages: [],
	settings: {
		site_preferences: {
			default_theme: "light",
			autoplay_videos: false,
			default_video_quality: "auto",
			default_language: "en_IN",
		},
	},
	about: {
		short_description: "",
		education: {
			course: "",
			institution: "",
			joined_at: commonDate,
			graduated_at: commonDate,
		},
	},
	awards_and_honors: [],
	licences_and_certifications: [],
	achievements: [],
	languages: [],
	skills: [],
	courses: [],
	work_experience: [],
	saved_content: [],
	saved_searches: [],
	search_metrics: {
		search_string: "",
		search_string_delimiter: "",
		search_points: 0,
		location_coordinates: [0, 0],
		search_filter_hashes: [],
	},
	followers: [],
	following: [],
	analytics: {
		views: [],
	},
	referrer: {
		is_referred: false,
		referral_source: "",
		referral_code: "",
		referral_id: "",
	},
	managed: false,
	assigned_to: [],
	created_at: commonDate,
	updated_at: commonDate,
	is_subscribed: false,
	subscription_information: {
		subscription_id: "",
		plan_slug: "user-free",
		end_date: new Date("2100-01-01"),
		active: false,
	},
	created_using_bd_tool: false,
	github_username: "none",
};
export const user = user_data;
export default user;
